













































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import Page from '@/layouts/Page.vue';
import ManagementLayout from '@/layouts/management/ManagementLayout.vue';
import SearchBar from '@/components/common/SearchBar.vue';
import ManagementList from '@/components/management/ManagementList.vue';
import ManagementItemFormTemplate from '@/components/management/ManagementItemFormTemplate.vue';
import { SubjectStores } from '@/store/subjects';
import { GeneralListItem, MultiListItem } from '@/models/management';
import { FormStores } from '@/store/forms';
import { FolderItem } from '@/models/miscellaneous';
import QuestionBankManagementList from '@/components/management/QuestionBankManagementList.vue';
import { QuestionBankStores } from '@/store/question_banks';
import { QuestionBankItemResponse, QuestionBankListFetchParams } from '@/models/question_banks';
import { PaginationDetails } from '@/models/generic';
import { FormItemSearchParams } from '@/models/forms';
import { SubjectItemSearchParams } from '@/models/subjects';
@Component({
  components: {
    Page,
    ManagementLayout,
    SearchBar,
    ManagementList,
    ManagementItemFormTemplate,
    QuestionBankManagementList,
  },
})
export default class QuestionBankListing extends Vue {
  currentFolders: FolderItem[] = [];
  currentSubjects: GeneralListItem[] = [];
  currentForms: GeneralListItem[] = [];
  currentQuestionBanks: QuestionBankItemResponse[] = [];
  isCreateFlowActive = false;
  isDeleteButtonDisabled = true;
  // Subjects
  subjectCreateStore = SubjectStores.create;
  subjectListStore = SubjectStores.list;
  subjectDetailStore = SubjectStores.details;
  // Forms
  formCreateStore = FormStores.create;
  formDetailStore = FormStores.details;
  formListStore = FormStores.list;

  // Question Bank List
  questionBankListStore = QuestionBankStores.list;
  questionBankDetailStore = QuestionBankStores.detail;
  // State
  questionBankStateStore = QuestionBankStores.state;

  // Checkbox Selection
  subjectCheckboxes: number[] = [];
  formCheckboxes: number[] = [];
  questionBankCheckboxes: number[] = [];

  // Pagination Details
  currentKeyword = ``;
  currentPage = 1;
  currentLimit = 10;
  currentTotalCount = 0;
  isPaginationReset = false;
  isPageSpecified = false;

  get isCurrentRouteManagement() {
    return this.$route.path === `/question_banks/listing`;
  }

  get folderNames() {
    return this.currentFolders;
  }

  get subjects() {
    return this.currentSubjects;
  }

  get forms() {
    return this.currentForms;
  }

  get questionBanks() {
    return this.currentQuestionBanks;
  }

  mounted() {
    if (this.questionBankStateStore.questionBankListingFolderState.length > 0 && this.questionBankStateStore.isModified) {
      // Refetch state from API depending on the previous folder state
      this.currentFolders = this.questionBankStateStore.questionBankListingFolderState;
      this.questionBankStateStore.updateQuestionBankListingIsModified(false);
      switch(this.currentFolders.length) {
        case 2: {
          // Containing Subjects and Subject Name. Fetch Subjects and levels
          this.currentSubjects = this.questionBankStateStore.subjectData;
          this.currentPage = this.questionBankStateStore.currentPageState[this.currentFolders.length];
          this.currentLimit = this.questionBankStateStore.currentLimitState[this.currentFolders.length];
          this.currentTotalCount = this.questionBankStateStore.currentTotalCountState[this.currentFolders.length];
          this.currentKeyword = this.questionBankStateStore.currentKeywordState[this.currentFolders.length];
          this.isPageSpecified = true;
          break;
        }
        case 3: {
          // Containing Subjects, Subject name and Question Bank Listing
          this.currentSubjects = this.questionBankStateStore.subjectData;
          this.currentForms = this.questionBankStateStore.formData;
          this.currentPage = this.questionBankStateStore.currentPageState[this.currentFolders.length];
          this.currentLimit = this.questionBankStateStore.currentLimitState[this.currentFolders.length];
          this.currentTotalCount = this.questionBankStateStore.currentTotalCountState[this.currentFolders.length];
          this.currentKeyword = this.questionBankStateStore.currentKeywordState[this.currentFolders.length];
          this.isPageSpecified = true;
          break;
        }
        default: {
          break;
        }
      }
      this.retrieveData();
    } else if (this.questionBankStateStore.questionBankListingFolderState.length > 0 && !this.questionBankStateStore.isModified) {
      this.currentFolders = this.questionBankStateStore.questionBankListingFolderState;
      // Get back previous states from stores
      this.currentSubjects = this.questionBankStateStore.subjectData;
      this.currentForms = this.questionBankStateStore.formData;
      this.currentQuestionBanks = this.questionBankStateStore.questionBankData;
      this.currentPage = this.questionBankStateStore.currentPageState[this.currentFolders.length];
      this.currentLimit = this.questionBankStateStore.currentLimitState[this.currentFolders.length];
      this.currentTotalCount = this.questionBankStateStore.currentTotalCountState[this.currentFolders.length];
      this.currentKeyword = this.questionBankStateStore.currentKeywordState[this.currentFolders.length];
      this.isPageSpecified = true;
    } else {
      this.currentFolders.push({ id: -1, name: 'Subjects' });
      this.retrieveData();
    }
  }

  beforeDestroy() {
    this.saveCurrentState();
  }

  @Watch(`subjectListStore.response`)
  setSubjects() {
    if (this.subjectListStore.response) {
      this.currentSubjects = [];
      this.currentTotalCount = this.subjectListStore.response.totalCount;
      this.subjectListStore.response.objects.forEach((item) => {
        this.currentSubjects.push({
          id: item.id,
          name: item.subjectName,
        });
      });
      this.questionBankStateStore.updateQuestionBankListingSubjectState(this.currentSubjects);
    }
  }

  @Watch(`formListStore.response`)
  setForms() {
    if (this.formListStore.response) {
      this.currentForms = [];
      this.currentTotalCount = 0;
      this.formListStore.response.objects.forEach((data) => {
        this.currentForms.push({
          id: data.id,
          name: data.formName,
        });
      });
      this.questionBankStateStore.updateQuestionBankListingFormState(this.currentForms);
    }
  }

  @Watch(`questionBankListStore.response`)
  setQuestionBanks() {
    if (this.questionBankListStore.response) {
      this.currentQuestionBanks = [];
      this.currentTotalCount = this.questionBankListStore.response.totalCount;
      this.questionBankListStore.response.objects.forEach((data) => {
        this.currentQuestionBanks.push({
          name: data.name,
          id: data.id,
          questionType: data.questionType,
        });
      });
      this.questionBankStateStore.updateQuestionBankListingState(this.currentQuestionBanks);
    }
  }

  @Watch(`subjectCreateStore.response`)
  @Watch(`subjectDetailStore.response`)
  updateCurrentList() {
    /*
        Create Response contains data
        Update / Delete Response does not contain data
        */
    if (this.subjectCreateStore.response || this.subjectDetailStore.response) {
      this.isCreateFlowActive = false;
      this.subjectListStore.retrieveSubjects({});
    }
  }

  @Watch(`formCreateStore.response`)
  @Watch(`formDetailStore.response`)
  updateCurrentFormList() {
    /*
        Create Response contains data
        Update / Delete Response does not contain data
        */
    if (this.formCreateStore.response || this.formDetailStore.response) {
      this.isCreateFlowActive = false;
      this.formListStore.retrieveForms({ subjectid: this.folderNames[this.folderNames.length - 1].id });
    }
  }

  @Watch(`questionBankDetailStore.response`)
  updateCurrentQuestionBankList() {
    if (this.questionBankDetailStore.response) {
      this.isCreateFlowActive = false;
      this.questionBankListStore.retrieveQuestionBanks({ formid: this.folderNames[this.folderNames.length - 1].id });
    }
  }

  redirectToCreateQuestionBank() {
    this.$router.push({
      path: `/question_banks/create`,
    });
  }

  redirectToEditQuestionBank(item: MultiListItem) {
    this.$router.push({
      path: `/question_banks/${item.id}/edit`,
    });
  }

  redirectToQuestionBankManagement() {
    if (this.$route.path !== `/question_banks`) {
      this.$router.push({
        path: `/question_banks`,
      });
    }
  }

  redirectToQuestionBankListing() {
    if (this.$route.path !== `/question_banks/listing`) {
      this.questionBankStateStore.resetState();
      this.$router.push({
        path: `/question_banks/listing`,
      });
    }
  }

  // Create Functions
  createSubject(payload: string) {
    this.subjectCreateStore.createNewSubject(payload);
    this.isCreateFlowActive = false;
  }

  createLevel(payload: string) {
    // Find the current level
    const currentSubject = this.folderNames[this.folderNames.length - 1];
    this.formCreateStore.createNewForm({ subjectId: currentSubject.id, formName: payload });
    this.isCreateFlowActive = false;
  }

  // Edit Functions
  updateSelectedSubject(payload: GeneralListItem) {
    this.subjectDetailStore.updateCurrentSubject({ id: payload.id, subjectName: payload.name });
  }

  updateSelectedLevel(payload: GeneralListItem) {
    this.formDetailStore.updateCurrentForm({ id: payload.id, formName: payload.name });
  }

  // Delete Functions
  deleteSelectedSubject(payload: GeneralListItem) {
    this.subjectDetailStore.deleteCurrentSubject({ id: payload.id, subjectName: payload.name });
  }

  deleteSelectedLevel(payload: GeneralListItem) {
    this.formDetailStore.deleteCurrentForm({ id: payload.id, formName: payload.name });
  }

  deleteSelectedQuestionBank(payload: GeneralListItem) {
    this.questionBankDetailStore.deleteQuestionBankById(payload.id);
  }

  // Folder Route function
  addFolderRouteForSubjects(payload: GeneralListItem) {
    this.saveCurrentState();
    this.folderNames.push({ ...payload });
    this.currentKeyword = ``;
    this.currentPage = 1;
    this.isPaginationReset = true;
    this.isCreateFlowActive = false;
    this.retrieveData();
  }

  addFolderRouteForForms(payload: GeneralListItem) {
    this.saveCurrentState();
    this.folderNames.push({ ...payload });
    this.currentKeyword = ``;
    this.currentPage = 1;
    this.isPaginationReset = true;
    this.isCreateFlowActive = false;
    this.retrieveData();
  }

  // Checkbox
  deleteSelectedSubjects(payload: number[]) {
    this.subjectCheckboxes = payload;
    this.isDeleteButtonDisabled = this.subjectCheckboxes.length > 0 ? false: true;
  }

  deleteSelectedForms(payload: number[]) {
    this.formCheckboxes = payload;
    this.isDeleteButtonDisabled = this.formCheckboxes.length > 0 ? false: true;
  }

  deleteSelectedQuestionBanks(payload: number[]) {
    this.questionBankCheckboxes = payload;
    this.isDeleteButtonDisabled = this.questionBankCheckboxes.length > 0 ? false : true;
  }

  deleteSelectedItems() { 
    switch(this.currentFolders.length) {
      case 2: {
        // Delete Multiple Subjects
        break;
      }
      case 3: {
        // Delete Multiple Forms
        break;
      }
      case 4: {
        // Delete multiple courses
        break;
      }
      default: {
        break;
      }
    }
  }

  // Pagination
  updatePagination(payload: PaginationDetails) {
    this.currentPage = payload.page;
    this.currentLimit = payload.limit;
    this.retrieveData();
  }

  resetFolders(payload: FolderItem[]) {
    this.currentFolders = payload;
    this.currentPage = this.questionBankStateStore.currentPageState[this.currentFolders.length];
    this.currentLimit = this.questionBankStateStore.currentLimitState[this.currentFolders.length];
    this.currentKeyword = this.questionBankStateStore.currentKeywordState[this.currentFolders.length];
    this.isCreateFlowActive = false;
    this.isPageSpecified = true;
    this.saveCurrentState();
    this.retrieveData();
  }

  retrieveData() {
    switch(this.currentFolders.length) {
        case 1: {
          // Base State: Containing Subjects only. Fetch Subjects only
          const payload: Partial<SubjectItemSearchParams> = {
            page: this.currentPage,
            limit: this.currentLimit,
          };
          if (this.currentKeyword.length > 0) {
            payload.keyword = this.currentKeyword;
          }
          this.subjectListStore.retrieveSubjects(payload);
          break;
        }
        case 2: {
          // Containing Subjects and Subject Name. Fetch Subjects and levels
          const payload: Partial<FormItemSearchParams> = {
              subjectid: this.currentFolders[this.currentFolders.length - 1].id
          };
          this.formListStore.retrieveForms(payload);
          break;
        }
        case 3: {
          // Containing Subjects, Subject name and Question Bank Listing
          const params : Partial<QuestionBankListFetchParams> = {
            formid: this.currentFolders[this.currentFolders.length - 1].id,
            page: this.currentPage,
            limit: this.currentLimit,
          };
          if (this.currentKeyword.length > 0 ) {
            params.keyword = this.currentKeyword;
          }
          this.questionBankListStore.retrieveQuestionBanks(params);
          break;
        }
        default: {
          break;
        }
      }
  }

  //Search
  searchItem(payload: string) {
    this.currentPage = 1;
    this.currentKeyword = payload;
    this.retrieveData();
  }

  // Pagination
  resetPaginationStatus(payload: boolean) {
    this.isPaginationReset = payload;
  }

  resetCurrentPageStatus(payload: boolean) {
    this.isPageSpecified = payload;
  }

  // Save Current State
  saveCurrentState() {
    this.questionBankStateStore.saveQuestionBankListingFolderState(this.currentFolders);
    this.questionBankStateStore.saveQuestionBankListingSubjectState(this.currentSubjects);
    this.questionBankStateStore.saveQuestionBankListingFormState(this.currentForms);
    this.questionBankStateStore.saveQuestionBankListingCurrentPage({ folderLevel: this.currentFolders.length, value: this.currentPage });
    this.questionBankStateStore.saveQuestionBankListingCurrentLimit({ folderLevel: this.currentFolders.length, value: this.currentLimit });
    this.questionBankStateStore.saveQuestionBankListingCurrentTotalCount({ folderLevel: this.currentFolders.length, value: this.currentTotalCount });
    this.questionBankStateStore.saveQuestionBankListingCurrentKeyword({ folderLevel: this.currentFolders.length, value: this.currentKeyword });
  }

}
